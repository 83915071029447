@tailwind base;
@tailwind components;

html {
  background: #000000;
}
html::-webkit-scrollbar {
  display: none;
}
h4 {
  color: yellow;
  font-size: large;
  font-weight: 500;
  font-family: anton;
}
.scrollbar::-webkit-scrollbar {
  display: none;
}
.card {
  /* Dark/Dark 700 */
  background: #1f1f1f;
  backdrop-filter: blur(3.10669px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 12px;
}
.button {
  clip-path: polygon(9% 0, 100% 0, 100% 70%, 91% 100%, 0 100%, 0 30%);
}
.rating {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}
input,
textarea {
  background-color: #1f1f1f;
  color: azure;
  padding: 4px 8px;
}
.img-input {
  background: linear-gradient(
    90.59deg,
    rgba(56, 55, 55, 0.5) -2.17%,
    rgba(152, 152, 152, 0) 110.76%
  );
  border: 2px dashed #303030;
  border-radius: 2px;
}
a {
  padding: 0 !important;
  margin-top: 0px !important;
}
a:hover {
  color: red;
}
.login {
  background-image: url("./assets/images/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 4px solid #970000;
  border-top: 4px solid #d22e2e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;

@font-face {
  font-family: "ANTON";
  src: url("../public/fonts/Anton/Anton-Regular.ttf");
}
